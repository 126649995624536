<div class="row">
    <div class="col-md-1">
    </div>
    <!-- <div class="col-md-10 my-auto"> -->
    <!-- <div class="col-md-8 mx-auto mt-5"> -->
    <div [ngClass]="+customizing?.allow_manualOrderInputField === 0?'col-md-8':'col-md-10'" class=" mx-auto mt-4">
        <form [formGroup]="form" (keyup.enter)="orders()" novalidate>
            <div class="container">
                <div class="row border border-2">
                    <div class="col-md-8 p-1 d-flex project">
                        <label *ngIf="role == 'shopfloor'">
                            {{'HOME.ORDERTOPIC' | translate}}
                        </label>
                        <label *ngIf="role == 'office'">
                            {{'HOME.ORDERTOPIC_OFFICE' | translate}}
                        </label>
                    </div>
                </div>
                <div class="row border-top-0 border border-2">
                    <div class="col border-end" *ngIf="+customizing?.allow_manualOrderInputField != 0">
                        <div class="col-md-10 d-flex p-3 mx-4 ">
                            <div class="row">
                                <div class="col-md-12 inputField">

                                    <input [ngClass]="{
                                        'is-invalid': (submitted && f['order'].errors) || input_item == false,
                                        'active': input_item != false
                                      }" type="text" #number_input name="order" id="testField" formControlName="order"
                                        *ngIf="role == 'shopfloor'" (keyup)="getinput()"
                                        class="form-control form-control-lg inputOrderNumber"
                                        placeholder="{{input_desc}}" style="display:inline-block; flex: 1;" autoFocus>

                                    <input type="text" #number_input name="order" *ngIf="role == 'office'"
                                        [ngClass]="{ 'is-invalid' :input_item == false  }" formControlName="order"
                                        id="testField" (keyup)="getinput()"
                                        class="form-control form-control-lg inputOrderNumber"
                                        placeholder="{{input_desc}}" style="display:inline-block; flex: 1;" autoFocus>

                                    <div *ngIf="submitted && f['order'].errors" class="invalid-feedback">
                                        <div *ngIf="f['order'].errors?.['required']"> {{'HOME.VALIDATIONMESSAGE' |
                                            translate}}</div>
                                    </div>
                                </div>

                                <select *ngIf="customerID === '3'" formControlName="projectExtention"
                                    class="form-control  selecttag3 " name="" id="">
                                    <option value="">PE</option>
                                    <option *ngFor="let list of projectExtentionValues" value="{{list}}">{{list}}
                                    </option>
                                </select>
                                <div class="col-md-12 p-2 mb-2 mrgtop">
                                    <button type="button" class=" confirmbutton btn btn-primary"
                                        (click)="orders()">{{'HOME.CONFIRMBUTTON'
                                        |
                                        translate}}</button>
                                </div>

                            </div>

                        </div>


                    </div>

                    <div class="col"
                        *ngIf="+customizing?.allow_createERPOrders === 1 || ( +customizing?.allow_createERPOrders === 0 )">
                        <div
                            [ngClass]="+customizing?.allow_manualOrderInputField === 0?'col-md-12 d-flex p-2 justify-content-center mt-2 mx-1':'col-md-10 d-flex p-3 justify-content-center mt-3 mx-3'">
                            <label style="color: #24597a;font-size: 18px;">Open orders</label>
                        </div>
                        <div [ngClass]="+customizing?.allow_manualOrderInputField === 0? 'col-md-12':'col-md-11'"
                            class=" p-2 mx-1 mt-2">
                            <button type="button" class="btn form-control-lg selectorder" (click)="open(content)"
                                data-bs-target="#exampleModal" *ngIf="role == 'shopfloor'   "> <i
                                    class="fa fa-external-link" aria-hidden="true"></i>
                                {{'HOME.SELECTBUTTON' | translate}} </button>
                            <button type="button" class="btn form-control-lg selectorder" (click)="open(content)"
                                data-bs-target="#exampleModal" *ngIf="role == 'office' "><i class="fa fa-external-link"
                                    aria-hidden="true"></i> {{'HOME.SELECTBUTTON_OFFICE' |
                                translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="container mt-4" *ngIf="customizing?.allow_StockManagement === '1'"> -->
            <div class="container mt-4" *ngIf="+customizing?.allow_StockManagement === 1">
                <div class="row border border-2">
                    <div class="col-md-8 p-1 d-flex project">
                        <label>
                            {{'HOME.PRODUCT_CARRIER_TOPIC' | translate}}
                        </label>
                    </div>
                </div>
                <div class="row border-top-0 border border-2">
                    <div class="col">
                        <div
                            [ngClass]="+customizing?.allow_manualOrderInputField === 0? 'col-md-12 p-2 mx-1 mt-2':'col-md-11 p-3 d-flex justify-content-center mt-2 mx-4'">
                            <button type="button" class="btn form-control-lg selectorder mb-0 ml-2"
                                (click)="open(productModal)"> <i class="fa fa-external-link" aria-hidden="true"></i>
                                {{'HOME.PRODUCT_CARRIERS' | translate}} </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<!-- Modal -->
<ng-template #content let-modal>
    <!-- <div class="modalProject"> -->
    <div class="modal-content modal-dialog-scrollable">
        <div class="modal-header">
            <h2 class="modal-title" id="exampleModalLabel" *ngIf="role == 'shopfloor'">Order</h2>
            <h2 class="modal-title" id="exampleModalLabel" *ngIf="role == 'office'">Project</h2>
            <button type="button" class="btn-close" (click)="modal.dismiss('Cross click');" aria-label="Close"></button>
        </div>
        <div class="modal-body projectbody">
            <div class="d-grid gap-2">
                <button class="btn create-order-btn"
                    *ngIf="customerID == '9' && +customizing?.allow_createERPOrders === 1" type="button"
                    (click)="NavigateTo('1')">
                    <i class="fa fa-plus-circle" aria-hidden="true"></i>
                    {{'HOME.CREATE_ORDER' | translate}}
                </button>
                <div class="search-bar" *ngIf="+customizing?.showSearchInERPOrderModal === 1">
                    <input type="text" class="form-control" id="searchitem" placeholder="Search"
                        onfocus="this.placeholder = ''" onblur="this.placeholder = 'Search'" [(ngModel)]="SearchText" />
                    <i class="fa fa-search" style="color: #24597A;"></i>
                    <i class="fa fa-times clear_search" (click)="clearSearch()"></i>
                </div>

                <button class="btn order-list-btn" *ngFor="let items of orderlist | filter:SearchText;let i = index"
                    [ngClass]="{focus:order_to_pass?order_to_pass?.mapping_field == items.mapping_field :order_to_pass?.orderID == items.orderID,'pinkcolor':(items.greenandgrey || items.yellowandred)}"
                    (click)="sendorder(items)" type="button">
                    <ng-container *ngIf="!(items.greenandgrey || items.yellowandred)">
                        <div class="order-list-details">
                            <p class="order-id">
                                {{items.orderID}} <img *ngIf="items.allowExecute == 'false'"
                                    src="../../assets/hand-rise.svg" alt="Rise hand" />
                            </p>
                            <p class="urgent_label" *ngIf="items?.urgent_label">{{items.urgent_label}}</p>
                            <p class="due-date">{{items.dueDate}}</p>

                        </div>

                        <div class=" ordlist displayitem">
                            <ng-container *ngFor="let displayitem of items.displayFields">
                                <label class="displayitem" [innerHTML]="displayitem"></label><br>
                            </ng-container>

                        </div>
                    </ng-container>
                    <ng-container *ngIf="(items.greenandgrey || items.yellowandred)">
                        <div class="order-list-wrapper">
                            <div class="order-list-details">

                                <p class="order-id">
                                    {{items.orderID}} <img *ngIf="items.allowExecute == 'false'"
                                        src="../../assets/hand-rise.svg" alt="Rise hand" />
                                </p>
                                <p class="urgent_label" *ngIf="items?.urgent_label">{{items.urgent_label}}</p>
                                <p class="due-date">{{items.dueDate}}</p>

                            </div>

                            <div class=" ordlist displayitem">
                                <ng-container *ngFor="let displayitem of items.displayFields">
                                    <label class="displayitem" [innerHTML]="displayitem"></label><br>
                                </ng-container>

                            </div>
                        </div>
                        <div class="order_currently_text">
                            <p *ngIf="items.greenandgrey">{{'HOME.ORDERINUSE' |
                                translate}}
                                {{items.displayName}}</p>
                            <p *ngIf="items.yellowandred"> {{'HOME.ORDERPARKEDSTATUS' |
                                translate}} {{items.displayName}}
                            </p>
                        </div>

                    </ng-container>
                    <!-- <ng-container *ngIf="(items.greenandgrey || items.yellowandred)">
                        <div class="container">
                            <div class="pinkcontainer">
                                <div class="order-list-details">
                                    <p class="order-id">
                                        {{items.orderID}} <img *ngIf="items.allowExecute == 'false'"
                                            src="../../assets/hand-rise.svg" alt="Rise hand" />
                                    </p>
                                    <p class="urgent_label" *ngIf="items?.urgent_label">{{items.urgent_label}}</p>
                                    <p class="due-date">{{items.dueDate}}</p>

                                </div>

                                <div class=" ordlist displayitem">
                                    <ng-container *ngFor="let displayitem of items.displayFields">
                                        <label class="displayitem">{{displayitem}}</label><br>
                                    </ng-container>

                                </div>
                            </div>
                            <div class="content">
                                <div class=" displayitem" *ngIf="items.greenandgrey">
                                    <label class="displayitem">Order currently being processed by
                                        {{items.displayName}}</label><br>

                                </div>
                                <div class=" displayitem" *ngIf="items.yellowandred">
                                    <label class="displayitem">Order parked by {{items.displayName}} (un)planned
                                        downtime </label><br>

                                </div>
                            </div>
                        </div>

                    </ng-container> -->

                </button>


            </div>
        </div>
        <div class="modal-footer" *ngIf="customerID != '11'">
            <button type="button" *ngIf="role != 'shopfloor'" class="btn btn-outline-dark canceldark "
                (click)="modal.dismiss('Cross click')">{{'HOME.CANCELBUTTON' | translate}}</button>
            <button type="submit" *ngIf="role != 'shopfloor'" class="btn btn-primary"
                (click)="onSubmit();modal.dismiss('Cross click');">{{'HOME.CONFIRMBUTTON' | translate}}</button>
            <div class="row d-flex  justify-content-center flex-grow-1 " *ngIf="role == 'shopfloor'">
                <button type="button" (click)="onSubmit();modal.dismiss('Cross click');"
                    class="btn btn-success sync p-1"
                    [ngClass]="{ disable: order_to_pass?.orderID == undefined || order_to_pass?.allowExecute == 'false' }">
                    {{'HOME.CONFIRMBUTTON' | translate}}
                </button>&nbsp;&nbsp;&nbsp;
                <button type="button" class="btn btn-success edit  p-1" *ngIf="+customizing?.allow_editERPOrders === 1"
                    [ngClass]="{ disable: order_to_pass?.orderID == undefined}" (click)="NavigateTo('2')">
                    {{'HOME.EDIT_LABEL' | translate}}
                </button>&nbsp;&nbsp;&nbsp;
                <button type="button" (click)="modal.dismiss('Cross click')" class="btn btn-success reset p-1">
                    {{'HOME.CANCELBUTTON' | translate}}
                </button>&nbsp;&nbsp;&nbsp;
            </div>
        </div>
        <div class="modal-footer" *ngIf="customerID == '11'">
            <button type="submit" class="btn btn-success p-1 sync"
                [ngClass]="{ disable: order_to_pass?.orderID == undefined}"
                (click)="onSubmit();modal.dismiss('Cross click');">{{'HOME.CONFIRMBUTTON' | translate}}</button>
            <button type="button" class="btn btn-success reset p-1"
                (click)="modal.dismiss('Cross click');">{{'HOME.CANCELBUTTON' | translate}}</button>
        </div>
    </div>
    <!-- </div> -->
</ng-template>

<ng-template #productModal let-modal>
    <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLabel">{{'HOME.PRODUCT_CARRIER_TOPIC' | translate}}</h2>
        <button type="button" class="btn-close" (click)="modal.dismiss('Cross click');clearSelectorder();"
            aria-label="Close"></button>
    </div>
    <div class="modal-body projectbody">
        <div class="d-inline">
            <label class="col-sm-6">Product</label>
            <label class="col-sm-6">{{'HOME.STOCK_QTY' | translate}}</label>
        </div>

        <!--  START: Product Component -->
        <div class="container productContainer mx-auto mt-2" (click)="getProduct(stockBuffer)"
            *ngFor="let stockBuffer of stockBufferList"
            [ngClass]="{ 'editProductContainer': (selectedProduct != undefined) &&  selectedProduct.Productname === stockBuffer.Productname }">

            <div class="row">
                <div class="col-md-6 mt-3 f-18">
                    {{stockBuffer.Description}}
                </div>

                <div class="col-md-6 mt-3 d-flex justify-content-end inventory">
                    {{stockBuffer.inventory}}
                </div>

                <div class="d-flex justify-content-end mb-3 productInput"
                    *ngIf="(selectedProduct != undefined) && selectedProduct?.Productname === stockBuffer.Productname">
                    <div class="col-sm-3 template">
                        <form [formGroup]="productForm" novalidate>
                            <input type="text" class="form-control inputOrder" [(ngModel)]="addedQty"
                                (keyup)="sumStockBuffer(stockBuffer)" formControlName="qty">
                        </form>
                        <label class="col-sm-3"> ___________<i class="fa fa-plus d-flex mt-2 mx-2"
                                aria-hidden="true"></i>
                        </label>
                        <label class="qty">{{isSumOfQty==true? stockBuffer.sumOfQty: stockBuffer.inventory}}</label>
                        <button type="submit" class="btn btn-primary"
                            (click)="updateQuantityDatabase(stockBuffer);clearSelectorder();">{{'HOME.SAVE_BUTTON' |
                            translate}}</button>
                    </div>
                </div>
            </div>
        </div>

        <!--  END: Product Component -->
        <!-- </form> -->
        <!-- <div class="row container editProductContainer mt-2 mx-auto">
                <div class="row">
                      <div class="col-md-6 mt-3">
                        Box 1
                      </div>
                      <div class="col-md-6 mt-3 d-flex justify-content-end">
                    245
                      </div>
                      <div class="d-flex justify-content-end mb-3 productInput">
                      <div class="col-sm-3 template">
                        <input type="text" #number_input name="order"
                            class="form-control form-control-lg inputOrder"
                            >
                            <label class="col-sm-3"> ______________<i class="fa fa-plus d-flex mt-2 mx-2" aria-hidden="true"></i>
                            </label>

                            <label>255</label>
                            <button type="submit" class="btn btn-primary"
                >{{'HOME.SAVE_BUTTON' | translate}}</button>
                    </div>
                </div>
                </div>
              </div> -->
        <!-- <button class="btn order-list-btn" *ngFor="let items of orderlist"
                    [ngClass]="{ focus: order_to_pass?.orderID == items.orderID }" (click)="sendorder(items)"
                    type="button">
                    <div class="order-list-details">
                        <p class="order-id">{{items.orderID}} <img *ngIf="items.allowExecute == 'false'"
                                src="../../assets/hand-rise.svg" alt="Rise hand" /></p>
                        <p>{{items.dueDate}}</p>
                    </div>
                    <div class="order-list-details" *ngIf="customerID == '9'">
                        <p>{{items.customerName}}</p>
                        <p>{{items.numberOfPallets}} pallets</p>
                    </div>
                </button> -->

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-success reset p-1"
            (click)="modal.dismiss('Cross click');clearSelectorder();">{{'HOME.CLOSEBUTTON' | translate}}</button>
    </div>
</ng-template>