import { Component, Inject, Injectable, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { base_routes } from 'src/app/constant';
import { DataService } from 'src/services/dataservice.service';
import { LandingPageComponent } from '../landing-page/landing-page.component';
import { DashboardComponent } from 'src/usermodule/dashboard/dashboard.component';
import { environment } from '../../environments/environment';
import { ApiService } from 'src/services/apiservice.service';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModalConfig, NgbModal, ModalDismissReasons, NgbDate } from '@ng-bootstrap/ng-bootstrap';
// import {NgbDateStruct, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { NgbCalendar, NgbDatepickerI18n, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslationWidth, WeekDay } from '@angular/common';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ChecklistModalComponent } from '../checklist-modal/checklist-modal.component';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { deLocale } from 'ngx-bootstrap/locale';

defineLocale('de', deLocale);
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import 'moment/locale/ja';
import 'moment/locale/fr';
import 'moment/locale/de';
import { categorie } from 'src/models/categories';
import { ToastrService } from 'ngx-toastr';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'DD MM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'DD MM YYYY',
  },
};

@Component({
  selector: 'app-down-time',
  templateUrl: './down-time.component.html',
  styleUrls: ['./down-time.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'nl-nl' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]

})


export class DownTimeComponent implements OnInit {
  minDate: Date;
  maxDate: Date;
  currentuser: any;
  categories: any;
  customerID: any;
  role: any;
  statusForm: any;
  closeResult: string;
  model: NgbDateStruct;
  dateOfBirth: any;
  @ViewChild('instructions') public modalComponent !: ChecklistModalComponent;
  @ViewChild('yellowpopup') private contentRef: TemplateRef<Object>;
  @ViewChild("popupformmodal") private popupmodal: TemplateRef<Object>;
  language_name: string | null;
  reminder: any;
  workstationid: string | null;
  SelectedCategory: categorie;
  checklist_flag: any;
  checkListFormGroup: any;
  checkListItems: any;
  active_order: any;
  popupform: any;
  dropdown_values: any[] = [];
  placeholder: any[] = [];
  values_b: any[];
  splitted_items: any[] = [];
  YellowEventID: string;
  constructor(private ChecklistModalComponent: ChecklistModalComponent, private config: NgbModalConfig, private _adapter: DateAdapter<any>,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
    private localeService: BsLocaleService, private modalService: NgbModal, public fb: UntypedFormBuilder, private apiservice: ApiService, private router: Router, private dataService: DataService, public LandingPageComponent: LandingPageComponent, private toastrService: ToastrService, public DashboardComponent: DashboardComponent) {
    this.workstationid = localStorage.getItem('workstationID');
    this.currentuser = localStorage.getItem('current_user');
    this.currentuser = JSON.parse(this.currentuser);
    this.customerID = localStorage.getItem('customerID');
    this.categories = localStorage.getItem("categories");
    this.language_name = localStorage.getItem("language");
    this.categories = JSON.parse(this.categories);
    this.checklist_flag = localStorage.getItem("showchecklist");
    this.role = localStorage.getItem("role");
    this.reminder = localStorage.getItem('reminder');
    (this.reminder != '' && this.reminder != null && this.reminder != undefined) ? this.reminder = JSON.parse(this.reminder as any) : this.reminder = [];
    this.statusForm = this.fb.group({
      date: ['', [Validators.required]],
    });
    this.popupform = this.fb.group({
      mail: ['', []],
    })
    this.config.backdrop = 'static';
    this.config.keyboard = false;
    this._locale = 'nl-nl';
    this._adapter.setLocale(this._locale);
    var currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    this.minDate = currentDate;
  }

  setLocaleDatepicker(item: string): void {
    if (item === 'du') {
      this._locale = 'nl-nl';
    } else {
      this._locale = item;
    }
    this._adapter.setLocale(this._locale);
  }

  ngOnInit() {
    this.dataService.currentMessage.subscribe((item: any) => {
      this.setLocaleDatepicker(item)
    });
  }

  SetTime() {
    let item = localStorage.getItem("DateForOrange")
    item = JSON.parse(item as any);
    if (this.statusForm.valid && item) {
      let year = this.statusForm.value.date._i.year;
      let month = this.statusForm.value.date._i.month + 1;
      let day = this.statusForm.value.date._i.date;
      let date = day + '-' + month + '-' + year;

      let datas = {
        "userID": this.currentuser.userID,
        "ordernr": (item as any)?.ordernr,
        "eventID": (item as any)?.eventID,
        "enddate": date
      };
      this.apiservice.SetTimerForYellow(datas).subscribe((item: any) => {
        this.modalService.dismissAll();
        this.LandingPageComponent.getorders(this.currentuser.userID, this.workstationid, '');
        localStorage.removeItem('DateForOrange');
        let data = [];
        this.reminder = this.reminder.filter((element: any) => {
          return (element.orderID != datas.ordernr)
        })
        if (this.reminder?.length != 0) {
          data = this.reminder;
          item.orderID = datas.ordernr;
          item.notify = false;
          data.push(item);
        } else {
          item.orderID = datas.ordernr;
          item.notify = false;
          data.push(item);
        }
        localStorage.setItem('reminder', JSON.stringify(data));
        this.LandingPageComponent.setNotification(data);
      });
    }
  }

  async setFormFields(item: any) {
    this.DashboardComponent.disabledOrder = true;
    (this.DashboardComponent.intervall) ? clearInterval(this.DashboardComponent.intervall) : '';
    (this.DashboardComponent.countuptimer) ? clearInterval(this.DashboardComponent.countuptimer) : '';
    localStorage.removeItem(this.currentuser.userID + 'countDownDate');
    this.DashboardComponent.t = '00:00:00'

    this.SelectedCategory = item;
    this.DashboardComponent.ordercounter = '00:00:00';
    this.DashboardComponent.currentOrderColor = 'orange';
    this.DashboardComponent.isShow = true;
    this.DashboardComponent.setIntervalCommmon()
    if (this.SelectedCategory.preventSkipExtraLevel == '1') {
      this.config.backdrop = 'static';
      this.config.keyboard = false;
    } else {
      this.config.backdrop = true;
      this.config.keyboard = true;
    }
    this.dropdown_values = [];
    this.placeholder = [];
    this.values_b = [];
    if (item.extraLevel != null && item.extraLevel != "") {
      if (item.extraLevel.includes('mailto') && item.extraLevel.includes('|')) {
        let mail_flag;
        this.splitted_items = item.extraLevel.split('|');
        var format = /[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g;
        if (this.splitted_items?.length > 0) {
          this.splitted_items.forEach((element: any) => {
            if (!format.test(element)) {
              this.placeholder.push(element)
              const fields = this.fb.group({
                [element]: []
              })
            } else {
              mail_flag = true;
            }
          });
        }
        if (mail_flag == true) {
          this.splitted_items.forEach((element: any) => {
            var arr_Str = element.split(/[<>]/);
            arr_Str.forEach((z: any) => {
              this.values_b.push(z);
            });
            this.values_b.forEach((y: any) => {
              var regex = /^[A-Za-z0-9&-; ]+$/
              var isValid = regex.test(y);
              if (isValid && y != '' && y != ';') {
                let t = y.substring(0, y.indexOf(" &"));
                t = (t == '') ? y : t;
                let flag;
                flag = new RegExp("\\b" + "Stuur" + "\\b").test(t);
                (flag == true) ? this.dropdown_values.push(t) : '';
              }
            });
          });
        }
      } else if (!item.extraLevel.includes('mailto') && item.extraLevel.includes(';') || item.extraLevel.includes('|')) {
        let temp = item.extraLevel.split('|');
        temp.forEach((element: any, index: any, object: any) => {
          if (element.includes(";")) {
            this.dropdown_values = element.split(";");
          }
          if (!element.includes(";")) {
            this.splitted_items = element.split(" ");
          }
        });
        if (this.splitted_items?.length > 0) {
          this.splitted_items.forEach((element: any) => {
            this.placeholder.push(element)
            const fields = this.fb.group({
              [element]: []
            })
          });
        }
      } else if (!item.extraLevel.includes('mailto') && !item.extraLevel.includes(';') && item.extraLevel.includes('|')) {
        this.splitted_items = item.extraLevel.split('|');
        if (this.splitted_items?.length > 0) {
          this.splitted_items.forEach((element: any) => {
            this.placeholder.push(element);
            const fields = this.fb.group({
              [element]: []
            })
          });
        }
      } else {
        if (!item.extraLevel.includes('mailto') && !item.extraLevel.includes(';') && !item.extraLevel.includes('|')) {
          this.placeholder.push(item.extraLevel);
          const fields = this.fb.group({
            [item.extraLevel]: []
          })
        }
      }

      for (let item in this.placeholder) {
        this.popupform.addControl(this.placeholder[item], new FormControl('', Validators.required));
      }
      this.open(this.popupmodal);
    }
    //  if(item.extraLevel == null || item.extraLevel == '')
    //  {
    // }
    this.setflag('', item);
    this.DashboardComponent.toggleDowntime(true)
  }

  async submitdropdown() {
    if (this.popupform.valid || +this.SelectedCategory?.extraLevel_mandatory === 0) {
      this.modalService.dismissAll();
      let yellowID = this.DashboardComponent?.YellowEventID;
      (yellowID && (Object.keys(this.popupform?.value).length > 0 || this.popupform?.value.mail != '')) ? this.PostFormFields(yellowID) : this.navigate();

    }
    else {
      this.toastrService.error('Please select any value!', 'Error!');
    }
  }

  async setflag(datas: any, item: any) {
    // this.SelectedCategory = item;
    // if (item.preventSkipExtraLevel == '1') {
    //   this.config.backdrop = 'static';
    //   this.config.keyboard = false;
    // } else {
    //   this.config.backdrop = true;
    //   this.config.keyboard = true;
    // }

    // event.target.disabled = true;
    let greendata = localStorage.getItem("green_eventdata");
    greendata = JSON.parse(greendata as any);
    let data: any = localStorage.getItem("active_order");
    data = JSON.parse(data as any);
    this.active_order = { orderID: data.orderID };
    let eventDescp: any = localStorage.getItem("green_subcat");
    let green_formfields: any = localStorage.getItem("green_formfields");
    (green_formfields != null && green_formfields != '' && green_formfields && green_formfields != undefined) ? green_formfields = JSON.parse(green_formfields) : '';
    (eventDescp != null && eventDescp != '' && eventDescp && eventDescp != undefined) ? eventDescp = JSON.parse(eventDescp) : '';
    (eventDescp) ? eventDescp : eventDescp = "working on the order";
    if (this.role == 'office') {
      this.navigate();
      if (this.LandingPageComponent.showorangetimer == true) {
        this.open(this.contentRef);
      }
      if (data) {
        (greendata) ? (await this.LandingPageComponent.stop(greendata, 'yellow', false)) : '';
        await this.LandingPageComponent.stop(data, 'yellow', false);
        (greendata) ? (await this.LandingPageComponent.createorder('yellow', item, greendata)) : '';
        (data && greendata) ? (await this.LandingPageComponent.createorder("green", eventDescp, data)) : '';
        (data && !greendata) ? (await this.LandingPageComponent.createorder("yellow", item, data)) : '';
        (green_formfields) ? (await this.LandingPageComponent.Post_formFields(green_formfields)) : '';
        // (this.checklist_flag == 'afterGreen') ?
        //     this.ChecklistModalComponent.getChecklistItems(this.currentuser?.userID, data?.orderID, this.checklist_flag,this.ChecklistModalComponent.instructionspopup) : '';
        localStorage.removeItem("green_eventdata");
        localStorage.removeItem("green_subcat");
        localStorage.removeItem("green_formfields");
        // setTimeout(() => {
        //   let yellowID = this.LandingPageComponent?.YellowEventID;
        //   (yellowID && (Object.keys(this.popupform?.value).length > 0 || this.popupform?.value.mail != '')) ? this.PostFormFields(yellowID) : this.navigate();
        // }, 4000);
      } else {
        (greendata) ? this.LandingPageComponent.stop(greendata, 'darkblue', false) : '';
        this.LandingPageComponent.order_viewdisable = true;
        this.LandingPageComponent.active_order = greendata;
        this.createorder('yellow', item, greendata);
        if (this.LandingPageComponent.darkblue_event?.length >= 1) {
          (await this.LandingPageComponent.stop(this.LandingPageComponent.active_order, 'darkblue', false));
        }
        let order: any = localStorage.getItem('darkblueorder');
        order = JSON.parse(order)
        this.apiservice.addorder(order).subscribe(async (res: any) => {
          if (res.response == 'success') {
            this.LandingPageComponent.navigate_cal();
            this.modalService.dismissAll();
            let data = {
              eventID: res.eventID,
              updateFields: [
                {
                  remark: order.remark
                }
              ]
            }
            this.apiservice.addformfield(data).subscribe(async (res: any) => {
            })
            this.LandingPageComponent.getorders(this.currentuser.userID, this.workstationid, false)
          }
        });
        localStorage.removeItem('darkblueorder');
        localStorage.removeItem("green_eventdata");
      }
    } else {
      this.navigate();
      // this.DashboardComponent.reportstatus_flag = true
      if (this.DashboardComponent.showorangetimer == true) {
        this.open(this.contentRef);
      }
      if (environment.Deactivate_timer == true) {
        (greendata) ? (await this.DashboardComponent.stop(greendata, 'yellow', false)) : '';
        (await this.DashboardComponent.stop(data, 'yellow', false));
        (greendata) ? (await this.DashboardComponent.createOrder('yellow', item, greendata, '')) : '';
        (data && greendata) ? (await this.DashboardComponent.createOrder("green", eventDescp, data, '')) : '';
        (data && !greendata) ? (await this.DashboardComponent.createOrder("yellow", item, data, '')) : '';
        (green_formfields) ? (await this.LandingPageComponent.Post_formFields(green_formfields)) : '';
        // (this.checklist_flag == 'afterGreen') ?
        // this.ChecklistModalComponent.getChecklistItems(this.currentuser?.userID, data?.orderID, this.checklist_flag) : '';
        localStorage.removeItem("green_eventdata");
        localStorage.removeItem("green_eventdata_subcat");
        localStorage.removeItem("green_formfields");
        // setTimeout(() => {
        //   let yellowID = this.DashboardComponent?.YellowEventID;
        //   (yellowID && (Object.keys(this.popupform?.value).length > 0 || this.popupform?.value.mail != '')) ? this.PostFormFields(yellowID) : this.navigate();
        // }, 5000);
      }
    }
  }

  async createorder(flag: any, item: any, order: any) {
    var unixtimestamp = Math.floor((new Date).getTime() / 1000);
    let datas = {
      orderNr: (this.DashboardComponent?.active_order) ? this.DashboardComponent.active_order.orderID : this.LandingPageComponent.active_order.orderID,
      customerRef: "",
      orderkey: "",
      userID: this.currentuser.userID,
      actionType: flag,
      eventDescription: item.text,
      actionDetail: item.value,
      workstationID: this.workstationid,
      monitorStartTime: unixtimestamp
    };
    this.apiservice.addorder(datas).subscribe(
      (res: any) => {
        this.YellowEventID = res.eventID;
        (this.role === 'office') ? (this.LandingPageComponent.getorders(this.LandingPageComponent.currentuser.userID, this.workstationid, false), this.LandingPageComponent.getnotes()) :
          (this.DashboardComponent.getOrders(this.DashboardComponent.currentuser.userID, this.workstationid, '', false),
            this.DashboardComponent.getNotes());
        (Object.keys(this.popupform?.value).length > 0 || this.popupform?.value.mail != '') ? this.PostFormFields(this.YellowEventID) : this.navigate();
      }, (error: any) => {
        console.log(error)
      })
  }

  async PostFormFields(YellowEventID: string) {
    let data: any = {
      eventID: YellowEventID,
      updateFields: [],
    };

    for (const [key, value] of Object.entries(this.popupform.value)) {
      if ((value as any).trim() != '') {
        data.updateFields.push({ [key]: value })
      }
    }
    this.apiservice.addformfield(data).subscribe(
      (res: any) => {
        this.popupform.reset({});
      }, (error) => {
      })
  }


  navigate() {
    (this.role === 'office') ? this.LandingPageComponent.set_flag('screen', '') : this.DashboardComponent.set_flag('screen', '');
  }

  async open(content: any) {
    this.config.backdrop = 'static';
    this.config.keyboard = false;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
